import { createSelector } from "reselect";

const selectBillingSlice = (state) => state.billing;

const selectIsPremium = createSelector(
  selectBillingSlice,
  (billing) => billing.premium
);

const selectFeatures = (state) => state.billing.features;

export const hasReachedFeatureLimit = createSelector(
  selectFeatures,
  selectIsPremium,
  (state, props) => props.featureName,
  (features, isPremium, featureName) => {
    if (isPremium) {
      return false;
    }

    const feature = features.find((feature) => feature.name === featureName);

    return feature && feature.limit_reached;
  }
);
