import React, { useMemo } from "react";
import { Bars4Icon, BellAlertIcon, TrashIcon } from "@heroicons/react/24/solid";
import { Dropdown } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { PencilIcon } from "@heroicons/react/24/outline";
import { deleteVisionBoard } from "vision/visionSlice";
import { MODAL_NAMES, toggleModal } from "slices/modalSlice";
import { deleteCustomQuotesCategory } from "quotes/quotesSlice";
import { hasReachedFeatureLimit } from "selectors/billingSelectors";
import { FEATURE_NAMES } from "constants";
import { selectNotificationSetting } from "selectors/notificationsSelectors";

const CustomQuoteCategoryDropdownMenu = ({ quoteCategory }) => {
  const emailNotificationLimitReached = useSelector((state) =>
    hasReachedFeatureLimit(state, {
      featureName: FEATURE_NAMES.email_notifications,
    })
  );

  const notificationSetting = useSelector((state) =>
    selectNotificationSetting(state, {
      ownerType: "CustomQuotesCategory",
      ownerId: quoteCategory.id,
    })
  );

  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deleteCustomQuotesCategory(quoteCategory.id));
  };

  const handleEdit = () => {
    dispatch(
      toggleModal({
        modalName: MODAL_NAMES.CREATE_OR_UPDATE_CUSTOM_QUOTES_CATEGORY,
        modalProps: { isCreate: false, customQuotesCategory: quoteCategory },
      })
    );
  };

  const handleNotifications = () => {
    if (emailNotificationLimitReached) {
      return dispatch(
        toggleModal({
          modalName: MODAL_NAMES.PREMIUM_FEATURE,
        })
      );
    }

    dispatch(
      toggleModal({
        modalName: MODAL_NAMES.CREATE_OR_UPDATE_NOTIFICATION_SETTING,
        modalProps: {
          isCreate: !notificationSetting,
          ownerType: "CustomQuotesCategory",
          ownerId: quoteCategory.id,
          notificationSetting,
        },
      })
    );
  };

  const handleMenuClick = ({ key, domEvent }) => {
    domEvent.stopPropagation();

    switch (key) {
      case "delete":
        handleDelete();
        break;
      case "edit":
        handleEdit();
        break;
      case "notifications":
        handleNotifications();
        break;
      default:
        break;
    }
  };

  const items = useMemo(() => {
    const defaultItems = [
      {
        key: "edit",
        label: "Edit",
        icon: <PencilIcon height="16" />,
      },
      {
        key: "notifications",
        label: "Notifications",
        icon: <BellAlertIcon height="16" />,
      },
      {
        key: "delete",
        danger: true,
        label: "Delete",
        icon: <TrashIcon height="16" />,
      },
    ];

    return defaultItems;
  }, []);

  return (
    <Dropdown menu={{ items, onClick: handleMenuClick }}>
      <Bars4Icon height={16} />
    </Dropdown>
  );
};

export default CustomQuoteCategoryDropdownMenu;
