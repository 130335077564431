import React, { useState } from "react";
import { Dropdown, Menu, Tooltip } from "antd";
import { Bars4Icon } from "@heroicons/react/24/solid";
import LBox from "components/LBox/LBox";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { deleteVisionItem } from "vision/visionSlice";
import { useDispatch } from "react-redux";
import { MODAL_NAMES, toggleModal } from "slices/modalSlice";

const VisionCard = ({ visionItem, onEdit, onDelete }) => {
  const dispatch = useDispatch();
  const [hovered, setHovered] = useState(false);

  // Check which date to display: created_at or updated_at
  const dateToDisplay =
    new Date(visionItem.updated_at) > new Date(visionItem.created_at)
      ? visionItem.updated_at
      : visionItem.created_at;

  // Parse the date using Intl.DateTimeFormat
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }).format(new Date(dateToDisplay));

  const handleDelete = () => {
    dispatch(deleteVisionItem(visionItem.id));
  };

  const handleMenuClick = (e) => {
    if (e.key === "edit") {
      dispatch(
        toggleModal({
          modalName: MODAL_NAMES.CREATE_OR_UPDATE_VISION_ITEM,
          modalProps: { isCreate: false, visionItem, type: visionItem.type },
        })
      );
    } else if (e.key === "delete") {
      handleDelete();
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="edit">
        <LBox flexRow alignCenter>
          <PencilIcon height="16" />
          <div style={{ paddingLeft: 4 }}>Edit</div>
        </LBox>
      </Menu.Item>
      <Menu.Item key="delete" danger>
        <LBox flexRow alignCenter>
          <TrashIcon height="16" />
          <div style={{ paddingLeft: 4 }}>Delete</div>
        </LBox>
      </Menu.Item>
    </Menu>
  );

  const renderContent = () => {
    switch (visionItem.type) {
      case "YOUTUBE":
        return (
          <iframe
            src={visionItem.youtube_url}
            frameBorder="0"
            allowFullScreen
            className="vision-card-iframe"
            title="YouTube Video"
          ></iframe>
        );
      case "NOTE":
        return <div className="vision-card-note">{visionItem.note}</div>;
      case "IMAGE":
        return (
          <img
            src={visionItem.image}
            alt="Vision"
            className="vision-card-image"
          />
        );
      default:
        return null;
    }
  };

  return (
    <div
      className={`vision-card`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {/* Card Content */}
      <div className="vision-card-content">{renderContent()}</div>

      {/* Display Created or Updated At Date */}
      <div className="vision-card-date">{formattedDate}</div>

      {/* Dropdown Menu Positioned at the Top Right */}
      <Tooltip title="Open Menu">
        <Dropdown overlay={menu} trigger={["click"]}>
          <div
            onClick={(e) => e.stopPropagation()} // Prevent triggering card click
            className="vision-card-menu-icon"
          >
            <Bars4Icon className="menu-icon" />
          </div>
        </Dropdown>
      </Tooltip>
    </div>
  );
};

export default VisionCard;
