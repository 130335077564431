import React, { useEffect, useState, useRef } from "react";
import { useNavigate, NavLink, Outlet } from "react-router-dom";
import LogoFull from "images/logo-full.jpg";
import "./Root.css";
import UserSettings from "UserSettings";
import Footer from "Footer";
import {
  XMarkIcon,
  PencilSquareIcon,
  HeartIcon,
  LightBulbIcon,
  ChatBubbleBottomCenterTextIcon,
} from "@heroicons/react/24/outline";
import { Bars3Icon } from "@heroicons/react/20/solid";
import GlobalComponents from "GlobalComponents";
import { getBillingData } from "slices/billingSlice";
import { useDispatch } from "react-redux";
import { getNotificationData } from "slices/notificationsSlice";

const Root = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    dispatch(getBillingData());
    dispatch(getNotificationData());
  }, []);

  useEffect(() => {
    if (
      !window.location.href.includes("quotes") &&
      !window.location.href.includes("gratitude") &&
      !window.location.href.includes("support") &&
      !window.location.href.includes("journal")
    ) {
      navigate("/vision");
    }

    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMobileMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [navigate]);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const closeMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <div id="root-container">
      <GlobalComponents />
      <div className="nav-container">
        <a onClick={() => navigate("/")}>
          <img
            src={LogoFull}
            style={{
              width: 130,
              height: 60,
              cursor: "pointer",
            }}
            alt="LumifyHub Logo"
          />
        </a>
        <div
          ref={menuRef}
          className={`nav-links ${mobileMenuOpen ? "open" : ""}`}
        >
          <NavLink
            className={({ isActive }) => (isActive ? "active-link" : "")}
            to={"/vision"}
            onClick={closeMenu}
          >
            <p className="nav-item">
              <LightBulbIcon height={24} />
              <span>Vision</span>
            </p>
          </NavLink>
          <NavLink
            className={({ isActive }) => (isActive ? "active-link" : "")}
            to={"/journal"}
            onClick={closeMenu}
          >
            <p className="nav-item">
              <PencilSquareIcon height={24} />
              <span>Journal</span>
            </p>
          </NavLink>
          <NavLink
            className={({ isActive }) => (isActive ? "active-link" : "")}
            to={"/gratitude"}
            onClick={closeMenu}
          >
            <p className="nav-item">
              <HeartIcon height={24} />
              <span>Gratitude</span>
            </p>
          </NavLink>
          <NavLink
            className={({ isActive }) => (isActive ? "active-link" : "")}
            to={"/quotes"}
            onClick={closeMenu}
          >
            <p className="nav-item">
              <ChatBubbleBottomCenterTextIcon height={24} />
              <span>Quotes</span>
            </p>
          </NavLink>
        </div>
        <div className="nav-right">
          <UserSettings />
        </div>
        <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
          {mobileMenuOpen ? (
            <XMarkIcon height={24} />
          ) : (
            <Bars3Icon height={24} />
          )}
        </div>
      </div>
      <div className="content-container">
        <Outlet />
      </div>
      {/*<Footer />*/}
    </div>
  );
};

export default Root;
