import React from "react";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";
import { MODAL_NAMES, toggleModal } from "slices/modalSlice";
import { updateActiveVisionBoardId } from "vision/visionSlice";
import { selectActiveVisionBoardId } from "vision/selectors";
import SidebarItem from "vision/SidebarItem";
import LText from "components/LText/LText";
import LBox from "components/LBox/LBox";
import { hasReachedFeatureLimit } from "selectors/billingSelectors";
import { FEATURE_NAMES } from "constants";

const Sidebar = ({ visionBoards }) => {
  const hasReachedVisionBoardLimit = useSelector((state) =>
    hasReachedFeatureLimit(state, {
      featureName: FEATURE_NAMES.unlimited_vision_boards,
    })
  );

  const dispatch = useDispatch();
  const activeVisionBoardId = useSelector(selectActiveVisionBoardId);

  const setSelectedBoard = (board) => {
    dispatch(updateActiveVisionBoardId(board.id));
  };

  return (
    <div className="vision-boards-sidebar">
      <LBox flexRowBetween alignCenter>
        <LText fs12 inactiveColor>
          Vision Boards
        </LText>
        <div className="add-button-container">
          <Tooltip title="New Vision Board">
            <span>
              <PlusCircleIcon
                height="30"
                className="add-button"
                onClick={() => {
                  if (hasReachedVisionBoardLimit) {
                    return dispatch(
                      toggleModal({
                        modalName: MODAL_NAMES.PREMIUM_FEATURE,
                      })
                    );
                  }

                  dispatch(
                    toggleModal({
                      modalName: MODAL_NAMES.CREATE_OR_UPDATE_VISION_BOARD,
                      modalProps: { isCreate: true },
                    })
                  );
                }}
              />
            </span>
          </Tooltip>
        </div>
      </LBox>
      {visionBoards.map((board) => (
        <SidebarItem
          key={board.id}
          activeVisionBoardId={activeVisionBoardId}
          board={board}
          setSelectedBoard={setSelectedBoard}
        />
      ))}
    </div>
  );
};

export default Sidebar;
