import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import React from "react";
import "./LEmpty.css";
const LEmpty = ({ text }) => {
  return (
    <div className="empty-state">
      <ExclamationCircleIcon className="empty-state-icon" />
      <p className="empty-state-text">{text}</p>
    </div>
  );
};

export default LEmpty;
