import { Button, Tooltip } from "antd";
import { createEntry, updateEntry } from "journal/journalSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveEntry } from "journal/journalSelectors";
import { isEmpty } from "lodash";
import { hasReachedFeatureLimit } from "selectors/billingSelectors";
import { FEATURE_NAMES } from "constants";
import { MODAL_NAMES, toggleModal } from "slices/modalSlice";
import LText from "components/LText/LText";

const Header = ({ wordCount }) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const entry = useSelector(selectActiveEntry);
  const [value, setValue] = useState(entry?.title || "");

  const saveTitle = () =>
    dispatch(
      updateEntry({
        id: entry.id,
        title: value,
      })
    );

  const handleBlur = () => {
    if (value !== entry.title) {
      saveTitle()
        .unwrap()
        .then(() => setIsEditing(false));
    } else {
      setIsEditing(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleBlur();
    }
  };

  return (
    <div className="journal-entry-new-btn-container" key={entry?.id}>
      <div style={{ width: "100%" }}>
        <input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="journal-entry-title-input"
          onClick={() => setIsEditing(true)}
          readOnly={!isEditing}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          placeholder={"Untitled Entry"}
        />
      </div>
      <LText mt8 fs12>
        {wordCount} {`${wordCount === 1 ? "word" : "words"}`}
      </LText>
    </div>
  );
};

export default Header;
