import ReactQuill from "react-quill";
import React, { useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveEntry } from "journal/journalSelectors";
import { debounce } from "lodash";
import { updateEntry } from "journal/journalSlice";
import "./Editor.css";
import { stripHtml } from "helpers/generalHelpers";

const Editor = ({ setWordCount }) => {
  const dispatch = useDispatch();
  const entry = useSelector(selectActiveEntry);
  const [value, setValue] = useState(entry?.content || "");
  const quillRef = useRef(null);

  const changeHandler = (newContent) => {
    dispatch(updateEntry({ id: entry.id, content: newContent }));
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );

  const updateWordCount = (text) => {
    const wordArray = text.trim().split(/\s+/);
    setWordCount(wordArray.filter((word) => word.length > 0).length);
  };

  const handleContentChange = (newContent) => {
    setValue(newContent);
    debouncedChangeHandler(newContent);

    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const text = editor.getText().replace(/\n+/g, " ").trim();



      // As the user types
      updateWordCount(text);
    }
  };

  // On initial load or when we change entries
  useEffect(() => {
    if (entry) {
      updateWordCount(entry.content);
      updateWordCount(stripHtml(entry.content));
    }
  }, [entry]);

  return (
    <div className="journal-entry-editor">
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={value}
        onChange={handleContentChange}
        scrollingContainer=".journal-entry-editor"
        placeholder="Start writing..."
        className="custom-quill"
      />
    </div>
  );
};

export default Editor;
