import React, { useMemo } from "react";
import { isEmpty, shuffle } from "lodash";
import { useSelector } from "react-redux";
import QuoteCard from "quotes/QuoteCard";

const Discover = ({ selectedCategory }) => {
  const standardQuotes = useSelector((state) => state.quotes.standardQuotes);
  const quoteCategories = useSelector((state) => state.quotes.quoteCategories);

  const quotes = useMemo(() => {
    const selectedCategoryId = Object.values(quoteCategories).find(
      (category) => category.name === selectedCategory
    )?.id;

    const quotesArray = Object.entries(standardQuotes).map(
      ([quoteId, quoteData]) => {
        return quoteData;
      }
    );

    if (!isEmpty(standardQuotes)) {
      const filteredQuotes =
        selectedCategory === "All"
          ? quotesArray
          : quotesArray.filter(
              (quote) => quote.quote_category_id === selectedCategoryId
            );

      return shuffle(filteredQuotes);
    }

    return [];
  }, [standardQuotes, selectedCategory]);

  return (
    <>
      {quotes.map((quote) => {
        return <QuoteCard quote={quote} key={`quote-${quote.id}`} />;
      })}
    </>
  );
};

export default Discover;
