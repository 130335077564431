import React, { useEffect, useMemo, useState } from "react";
import "quotes/Quotes.css";
import {
  BriefcaseIcon,
  MagnifyingGlassIcon,
  StarIcon,
} from "@heroicons/react/24/outline";
import { getData } from "quotes/quotesSlice";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import Discover from "quotes/Discover";
import Starred from "quotes/Starred";
import Sidebar from "quotes/Sidebar";
import Custom from "quotes/Custom";
import CustomQuotesSidebar from "quotes/CustomQuotesSidebar";
import AddCustomQuote from "quotes/AddCustomQuote";

const PAGE_COMPONENT_MAP = {
  discover: Discover,
  starred: Starred,
  custom: Custom,
};

const QuotesView = () => {
  const dispatch = useDispatch();
  const [activeMenu, setActiveMenu] = useState("custom");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const isCustomQuotes = activeMenu === "custom";

  const fetchingInitialData = useSelector(
    (state) => state.quotes.fetchingInitialData
  );

  useEffect(() => {
    dispatch(getData());
  }, [dispatch]);

  if (fetchingInitialData) {
    return <Spin />;
  }

  const ActiveComponent = PAGE_COMPONENT_MAP[activeMenu];

  return (
    <div className="quotes-wrapper">
      {!isCustomQuotes && (
        <Sidebar
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
      )}
      {isCustomQuotes && <CustomQuotesSidebar />}
      <div className="quotes-content">
        <div className="quotes-submenu">
          <p
            className={`menu-button ${
              activeMenu === "custom" ? "menu-button-selected" : ""
            }`}
            onClick={() => setActiveMenu("custom")}
          >
            <BriefcaseIcon height={24} /> My Quotes
          </p>
          <p
            className={`menu-button ${
              activeMenu === "discover" ? "menu-button-selected" : ""
            }`}
            onClick={() => setActiveMenu("discover")}
          >
            <MagnifyingGlassIcon height={24} /> Discover
          </p>
          <p
            className={`menu-button ${
              activeMenu === "starred" ? "menu-button-selected" : ""
            }`}
            onClick={() => setActiveMenu("starred")}
          >
            <StarIcon height={24} /> Starred
          </p>
        </div>
        <div className="quotes-container">
          <ActiveComponent selectedCategory={selectedCategory} />
        </div>
      </div>
      {isCustomQuotes && <AddCustomQuote />}
    </div>
  );
};

export default QuotesView;
