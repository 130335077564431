import React, { useEffect, useMemo, useState } from "react";
import "./Gratitude.css";
import ReactQuill from "react-quill";

import { ClockIcon, CogIcon, PencilIcon } from "@heroicons/react/24/outline";
import { Button, Divider, Dropdown, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  createGratitudeEntry,
  getGratitudeEntries,
  deleteGratitudeEntry,
} from "gratitude/gratitudeSlice";
import { isEmpty } from "lodash";
import { TrashIcon } from "@heroicons/react/24/solid";
import { Bars3Icon } from "@heroicons/react/20/solid";
import { MODAL_NAMES, toggleModal } from "slices/modalSlice";
import { hasReachedFeatureLimit } from "selectors/billingSelectors";
import { FEATURE_NAMES } from "constants";
import LEmpty from "components/LEmpty/LEmpty";
import LText from "components/LText/LText";

const { Option } = Select;

const GratitudeView = () => {
  const hasReachedGratitudeEntryLimit = useSelector((state) =>
    hasReachedFeatureLimit(state, {
      featureName: FEATURE_NAMES.unlimited_gratitude_entries,
    })
  );
  const [isChangingPrompt, setIsChangingPrompt] = useState(false);
  const [entry, setEntry] = useState("");
  const [prompt, setPrompt] = useState("What are you grateful for today?");

  const dispatch = useDispatch();

  const fetchingInitialData = useSelector(
    (state) => state.quotes.fetchingInitialData
  );

  const prompts = useSelector((state) => state.gratitude.prompts);
  const gratitudeEntries = useSelector(
    (state) => state.gratitude.gratitudeEntries
  );

  const gratitudeEntriesSorted = Object.entries(gratitudeEntries)
    .map(([_, entryData]) => {
      return { ...entryData };
    })
    .sort((a, b) => b.id - a.id);

  useEffect(() => {
    dispatch(getGratitudeEntries());
  }, [dispatch]);

  if (fetchingInitialData) {
    return <Spin />;
  }

  const handleChangePrompt = (val) => {
    setPrompt(val);
    setIsChangingPrompt(false);
  };

  const handleEnablePromptEditingMode = () => {
    setIsChangingPrompt(!isChangingPrompt);
  };

  const handleSaveEntry = () => {
    if (hasReachedGratitudeEntryLimit) {
      return dispatch(
        toggleModal({
          modalName: MODAL_NAMES.PREMIUM_FEATURE,
        })
      );
    }

    dispatch(
      createGratitudeEntry({
        prompt,
        content: entry,
      })
    ).then(() => {
      setEntry("");
    });
  };

  const handleDelete = (id) => {
    dispatch(deleteGratitudeEntry(id));
  };

  const handleEdit = (id) => {
    dispatch(
      toggleModal({
        modalName: MODAL_NAMES.UPDATE_GRATITUDE_ENTRY,
        modalProps: { gratitudeEntry: gratitudeEntries[id] },
      })
    );
  };

  const generateItems = (entryId) => [
    {
      key: "edit",
      label: "Edit",
      icon: <PencilIcon height="16" />,
      onClick: () => handleEdit(entryId),
    },
    {
      key: "delete",
      label: "Delete",
      danger: true,
      icon: <TrashIcon height="16" />,
      onClick: () => handleDelete(entryId),
    },
  ];

  return (
    <div className={"gratitude-container"}>
      <div className={"gratitude-editor-container"}>
        {isChangingPrompt && (
          <div className={"gratitude-prompt"}>
            <Select
              style={{ width: "50%" }}
              defaultValue={prompt}
              onChange={handleChangePrompt}
              onSelect={handleChangePrompt}
              onBlur={() => setIsChangingPrompt(false)}
            >
              {prompts.map((p) => (
                <Option key={p} value={p}>
                  {p}
                </Option>
              ))}
            </Select>
          </div>
        )}
        {!isChangingPrompt && (
          <div
            className={"gratitude-prompt"}
            onClick={handleEnablePromptEditingMode}
          >
            <h1>{prompt}</h1>
          </div>
        )}
        <ReactQuill
          theme="snow"
          value={entry}
          onChange={setEntry}
          scrollingContainer="html"
          className="custom-quill"
        />

        <div className={"save-entry-btn"}>
          <Button
            type={"primary"}
            onClick={handleSaveEntry}
            disabled={isEmpty(entry)}
          >
            Save Entry
          </Button>
        </div>
      </div>
      <Divider>
        <div className={"past-entries-text"}>
          <ClockIcon height={24} />
          <LText fs12 inactiveColor>
            Past Entries
          </LText>
        </div>
      </Divider>
      <ul className={"gratitude-entries-container"}>
        {gratitudeEntriesSorted.map((entry) => (
          <li className={"gratitude-entry-card"} key={entry.id}>
            <div className={"gratitude-card-header"}>
              <div>
                <p className={"gratitude-entry-date highlight"}>
                  {entry.created_at}
                </p>
                <p className={"gratitude-entry-prompt"}>{entry.prompt}</p>
              </div>
              <Dropdown
                menu={{ items: generateItems(entry.id) }}
                overlayClassName="entry-dropdown"
              >
                <Bars3Icon height={24} />
              </Dropdown>
            </div>
            <div
              className={"gratitude-entry-body"}
              dangerouslySetInnerHTML={{ __html: entry.content }}
            ></div>
          </li>
        ))}
        {gratitudeEntriesSorted.length === 0 && (
          <LEmpty text="No past entries found. Start by creating a new gratitude entry!" />
        )}
      </ul>
    </div>
  );
};

export default GratitudeView;
