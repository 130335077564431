import { useSelector } from "react-redux";
import React, { useMemo } from "react";
import { FolderIcon } from "@heroicons/react/24/solid";
import LBox from "components/LBox/LBox";

const Sidebar = ({ selectedCategory, setSelectedCategory }) => {
  const quoteCategories = useSelector((state) => state.quotes.quoteCategories);

  const categories = useMemo(() => {
    const categoryNames = Object.values(quoteCategories).map(
      (category) => category.name
    );
    // Separate "All" category and sort others alphabetically
    const sortedCategories = categoryNames
      .filter((category) => category.toLowerCase() !== "all")
      .sort((a, b) => a.localeCompare(b));

    // Place "All" category at the top
    if (categoryNames.includes("All")) {
      sortedCategories.unshift("All");
    }

    return sortedCategories;
  }, [quoteCategories]);

  return (
    <div className="categories-sidebar">
      {categories.map((category) => (
        <p
          key={category}
          className={`category-button ${
            selectedCategory === category ? "category-button-selected" : ""
          }`}
          onClick={() => setSelectedCategory(category)}
        >
          <LBox flexRowStart>
            <div>
              <FolderIcon height={16} style={{ marginRight: 8 }} />
            </div>
            {category}
          </LBox>
        </p>
      ))}
    </div>
  );
};

export default Sidebar;
