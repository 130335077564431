import { createSelector } from "reselect";
import { isEmpty } from "lodash";

const selectNotificationsSlice = (state) => state.notifications;

const selectNotificationsSettings = createSelector(
  selectNotificationsSlice,
  (state) => state.notificationSettings
);

const selectNotificationSettingsAsArray = createSelector(
  selectNotificationsSettings,
  (notifications) => {
    if (isEmpty(notifications)) {
      return [];
    }

    return Object.values(notifications);
  }
);

export const selectNotificationSetting = createSelector(
  selectNotificationSettingsAsArray,
  (state, props) => props.ownerId,
  (state, props) => props.ownerType,
  (notifications, ownerId, ownerType) => {
    if (isEmpty(notifications)) {
      return null;
    }

    return notifications.find(
      (notification) =>
        notification.owner_id === ownerId &&
        notification.owner_type === ownerType
    );
  }
);
