import React, { useState } from "react";
import {
  Bars4Icon,
  StarIcon as StarIconSolid,
} from "@heroicons/react/24/solid";
import { PencilIcon, StarIcon, TrashIcon } from "@heroicons/react/24/outline";
import { deleteCustomQuote, toggleQuoteStar } from "quotes/quotesSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectQuoteIsStarred } from "quotes/selectors";
import { Dropdown, Menu, Tooltip } from "antd";
import LBox from "components/LBox/LBox";
import { MODAL_NAMES, toggleModal } from "slices/modalSlice";

const QuoteCard = ({ quote, isCustomQuote = false }) => {
  const [hovered, setHovered] = useState(false);
  const quoteIsStarred = useSelector((state) =>
    selectQuoteIsStarred(state, { quoteId: quote.id })
  );
  const dispatch = useDispatch();

  const handleToggleStar = () => {
    dispatch(toggleQuoteStar(quote.id));
  };

  const StarComponent = quoteIsStarred ? StarIconSolid : StarIcon;

  const handleDelete = () => {
    dispatch(deleteCustomQuote(quote.id));
  };

  const handleMenuClick = (e) => {
    if (e.key === "edit") {
      dispatch(
        toggleModal({
          modalName: MODAL_NAMES.CREATE_OR_UPDATE_CUSTOM_QUOTE,
          modalProps: { isCreate: false, customQuote: quote },
        })
      );
    } else if (e.key === "delete") {
      handleDelete();
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="edit">
        <LBox flexRow alignCenter>
          <PencilIcon height="16" />
          <div style={{ paddingLeft: 4 }}>Edit</div>
        </LBox>
      </Menu.Item>
      <Menu.Item key="delete" danger>
        <LBox flexRow alignCenter>
          <TrashIcon height="16" />
          <div style={{ paddingLeft: 4 }}>Delete</div>
        </LBox>
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      className={`quotes-card`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {quote.content && (
        <div className="quote-content-container">
          <p>{quote.content}</p>
          {quote.author && (
            <p className="quote-author">
              <i>{quote.author}</i>
            </p>
          )}
        </div>
      )}

      {!isCustomQuote && (
        <Tooltip title={quoteIsStarred ? "Unstar Quote" : "Star Quote"}>
          <div onClick={handleToggleStar} className="vision-card-menu-icon">
            <StarComponent className="star-icon" />
          </div>
        </Tooltip>
      )}
      {isCustomQuote && (
        <Tooltip title="Open Menu">
          <Dropdown overlay={menu} trigger={["click"]}>
            <div
              onClick={(e) => e.stopPropagation()} // Prevent triggering card click
              className="vision-card-menu-icon"
            >
              <Bars4Icon className="menu-icon" />
            </div>
          </Dropdown>
        </Tooltip>
      )}
    </div>
  );

  return (
    <div className={"quotes-card-only-text-black"}>
      <div>
        {!isCustomQuote && (
          <Tooltip title={quoteIsStarred ? "Unstar Quote" : "Star Quote"}>
            <div onClick={handleToggleStar}>
              <StarComponent className="star-icon" />
            </div>
          </Tooltip>
        )}
        {isCustomQuote && (
          <div onClick={handleToggleStar}>
            <Tooltip title="Open Menu">
              <Dropdown overlay={menu} trigger={["click"]}>
                <div
                  onClick={(e) => e.stopPropagation()} // Prevent triggering card click
                >
                  <Bars4Icon className="menu-icon" />
                </div>
              </Dropdown>
            </Tooltip>
          </div>
        )}
      </div>
      {quote.content && (
        <div className="quote-content-container">
          <p>{quote.content}</p>
          {quote.author && (
            <p className="quote-author">
              <i>{quote.author}</i>
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default QuoteCard;
