import React, { useState } from "react";
import EntryDropdownMenu from "journal/EntryDropdownMenu";
import BoardDropdownMenu from "vision/BoardDropdownMenu";
import { FolderIcon } from "@heroicons/react/24/solid";
import LBox from "components/LBox/LBox";

const SidebarItem = ({ board, activeVisionBoardId, setSelectedBoard }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <p
      key={board.id}
      className={`board-button ${
        board.id === activeVisionBoardId ? "board-button-selected" : ""
      }`}
      onClick={() => setSelectedBoard(board)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <LBox flexRowStart>
        <div>
          <FolderIcon height={16} style={{ marginRight: 8 }} />
        </div>
        {board.name}
      </LBox>
      {hovered && <BoardDropdownMenu board={board} />}
    </p>
  );
};

export default SidebarItem;
