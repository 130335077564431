import React, { useState } from "react";
import EntryDropdownMenu from "journal/EntryDropdownMenu";
import BoardDropdownMenu from "vision/BoardDropdownMenu";
import CustomQuoteCategoryDropdownMenu from "quotes/CustomQuoteCategoryDropdownMenu";
import { FolderIcon } from "@heroicons/react/24/solid";
import LBox from "components/LBox/LBox";

const CustomQuoteSidebarItem = ({
  quoteCategory,
  activeCustomQuotesCategoryId,
  setSelectedCategory,
}) => {
  const [hovered, setHovered] = useState(false);

  return (
    <p
      key={quoteCategory.id}
      className={`board-button ${
        quoteCategory.id === activeCustomQuotesCategoryId
          ? "board-button-selected"
          : ""
      }`}
      onClick={() => setSelectedCategory(quoteCategory)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <LBox flexRowStart>
        <div>
          <FolderIcon height={16} style={{ marginRight: 8 }} />
        </div>
        {quoteCategory.name}
      </LBox>
      {hovered && (
        <CustomQuoteCategoryDropdownMenu quoteCategory={quoteCategory} />
      )}
    </p>
  );
};

export default CustomQuoteSidebarItem;
