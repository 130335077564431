import { Avatar, Dropdown, Typography } from "antd";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ArrowLeftOnRectangleIcon,
  Cog8ToothIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/solid";
import { logout } from "slices/userSlice";
import { MODAL_NAMES, toggleModal } from "slices/modalSlice";

const { Paragraph } = Typography;

const UserSettings = () => {
  const dispatch = useDispatch();
  const firstName = useSelector((state) => state.user.user.first_name);

  const handleSignOut = () => {
    dispatch(logout());
  };

  const handleMenuClick = ({ key, domEvent }) => {
    domEvent.stopPropagation();

    switch (key) {
      case "sign-out":
        handleSignOut();
        break;
      default:
        break;
    }
  };

  const items = useMemo(() => {
    const defaultItems = [
      {
        key: "settings",
        label: "Settings",
        icon: <Cog8ToothIcon height="16" />,
        onClick: () => {
          dispatch(toggleModal({ modalName: MODAL_NAMES.ACCOUNT }));
        },
      },
      {
        key: "sign-out",
        danger: true,
        label: "Sign Out",
        icon: <ArrowLeftOnRectangleIcon height="16" />,
      },
      {
        key: "help",
        label: "Help",
        icon: <QuestionMarkCircleIcon height="16" />,
        onClick: () => {
          dispatch(
            toggleModal({
              modalName: MODAL_NAMES.INFO,
              modalProps: {
                infoTitle: "Need help?",
                content: (
                  <Paragraph style={{ marginTop: 10 }}>
                    Send an e-mail to{" "}
                    <a href="mailto:hello@lumifyhub.io">hello@lumifyhub.io</a>{" "}
                    and we'll get back to you pretty quickly.
                  </Paragraph>
                ),
              },
            })
          );
        },
      },
    ];

    return defaultItems;
  }, []);

  return (
    <Dropdown menu={{ items, onClick: handleMenuClick }}>
      <Avatar style={{ cursor: "pointer" }}>
        {firstName[0].toUpperCase()}
      </Avatar>
    </Dropdown>
  );
};

export default UserSettings;
