import React, { useMemo } from "react";
import { isEmpty, shuffle } from "lodash";
import { useSelector } from "react-redux";
import QuoteCard from "quotes/QuoteCard";
import LEmpty from "components/LEmpty/LEmpty";

const Starred = () => {
  const standardQuotes = useSelector((state) => state.quotes.standardQuotes);

  const starredQuotes = useSelector((state) => state.quotes.starredQuotes);

  const quotes = useMemo(() => {
    if (!isEmpty(standardQuotes)) {
      return shuffle(
        Object.entries(standardQuotes)
          .filter(([quoteId], _) => !!starredQuotes[quoteId])
          .map(([_, quoteData]) => {
            return quoteData;
          })
      );
    }

    return [];
  }, [standardQuotes, starredQuotes]);

  return (
    <>
      {quotes.map((quote) => {
        return <QuoteCard quote={quote} key={`starred-quote-${quote.id}`} />;
      })}
      {isEmpty(quotes) && (
        <LEmpty text="No starred quotes currently. You can star a quote by hovering over it and clicking the star icon in the top right corner" />
      )}
    </>
  );
};

export default Starred;
