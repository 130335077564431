import React, { useEffect, useState } from "react";
import "./Vision.css";
import Sidebar from "./Sidebar";
import VisionCard from "./VisionCard";
import { Spin } from "antd";
import AddItem from "vision/AddItem";
import { useDispatch, useSelector } from "react-redux";
import { getVisionData } from "vision/visionSlice";
import {
  selectVisionBoardsArray,
  selectVisionItemsForActiveBoard,
} from "vision/selectors";
import {
  ExclamationCircleIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";
import LEmpty from "components/LEmpty/LEmpty";

const VisionView = () => {
  const fetchingInitialData = useSelector(
    (state) => state.vision.fetchingInitialData
  );

  const visionBoards = useSelector((state) => selectVisionBoardsArray(state));
  const visionItems = useSelector((state) =>
    selectVisionItemsForActiveBoard(state)
  );

  const dispatch = useDispatch();
  const [selectedBoard, setSelectedBoard] = useState(1); // Default to the first board

  useEffect(() => {
    dispatch(getVisionData());
  }, [dispatch]);

  if (fetchingInitialData) {
    return <Spin />;
  }

  return (
    <div className="vision-main">
      <div className="add-item-container">
        <AddItem />
      </div>
      <div className="vision-wrapper">
        <Sidebar
          visionBoards={visionBoards}
          setSelectedBoard={setSelectedBoard}
        />
        <div className="vision-content">
          <div className="vision-container">
            {visionItems.length > 0 ? (
              visionItems.map((item) => (
                <VisionCard key={item.id} visionItem={item} />
              ))
            ) : (
              <LEmpty text="No vision items found. Start by adding a new vision item!" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisionView;
