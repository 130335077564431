import React, { useMemo } from "react";
import { Bars4Icon, TrashIcon, BellAlertIcon } from "@heroicons/react/24/solid";
import { Dropdown } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { PencilIcon } from "@heroicons/react/24/outline";
import { deleteVisionBoard } from "vision/visionSlice";
import { MODAL_NAMES, toggleModal } from "slices/modalSlice";
import { selectNotificationSetting } from "selectors/notificationsSelectors";
import { hasReachedFeatureLimit } from "selectors/billingSelectors";
import { FEATURE_NAMES } from "constants";

const BoardDropdownMenu = ({ board }) => {
  const emailNotificationLimitReached = useSelector((state) =>
    hasReachedFeatureLimit(state, {
      featureName: FEATURE_NAMES.email_notifications,
    })
  );

  const dispatch = useDispatch();
  const notificationSetting = useSelector((state) =>
    selectNotificationSetting(state, {
      ownerType: "VisionBoard",
      ownerId: board.id,
    })
  );

  const handleDelete = () => {
    dispatch(deleteVisionBoard(board.id));
  };

  const handleEdit = () => {
    dispatch(
      toggleModal({
        modalName: MODAL_NAMES.CREATE_OR_UPDATE_VISION_BOARD,
        modalProps: { isCreate: false, visionBoard: board },
      })
    );
  };

  const handleNotifications = () => {
    if (emailNotificationLimitReached) {
      return dispatch(
        toggleModal({
          modalName: MODAL_NAMES.PREMIUM_FEATURE,
        })
      );
    }

    dispatch(
      toggleModal({
        modalName: MODAL_NAMES.CREATE_OR_UPDATE_NOTIFICATION_SETTING,
        modalProps: {
          isCreate: !notificationSetting,
          ownerType: "VisionBoard",
          ownerId: board.id,
          notificationSetting,
        },
      })
    );
  };

  const handleMenuClick = ({ key, domEvent }) => {
    domEvent.stopPropagation();

    switch (key) {
      case "delete":
        handleDelete();
        break;
      case "edit":
        handleEdit();
        break;
      case "notifications":
        handleNotifications();
      default:
        break;
    }
  };

  const items = useMemo(() => {
    const defaultItems = [
      {
        key: "edit",
        label: "Edit",
        icon: <PencilIcon height="16" />,
      },
      {
        key: "notifications",
        label: "Notifications",
        icon: <BellAlertIcon height="16" />,
      },
      {
        key: "delete",
        danger: true,
        label: "Delete",
        icon: <TrashIcon height="16" />,
      },
    ];

    return defaultItems;
  }, []);

  return (
    <Dropdown menu={{ items, onClick: handleMenuClick }}>
      <div>
        <Bars4Icon height={16} />
      </div>
    </Dropdown>
  );
};

export default BoardDropdownMenu;
