import { Button, Form, Input, Layout } from "antd";
import React, { useEffect } from "react";
import "auth/Auth.css";
import { checkLoggedIn, login } from "slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useLocation } from "react-router-dom";
import useToast from "hooks/useToast";
import Logo from "images/logo-full.jpg";
import LText from "components/LText/LText";
import LBox from "components/LBox/LBox";

const Login = ({ type }) => {
  const [form] = Form.useForm();
  const { toastError, toastInfo } = useToast();
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const from = state?.from || "/";

  useEffect(() => {
    if (user) return;

    dispatch(checkLoggedIn());
  }, []);

  const handleSubmit = (values) => {
    dispatch(
      login({
        email: values.email,
        password: values.password,
        code: values.code,
      })
    )
      .unwrap()
      .catch((error) => {
        console.log("error", error);
        toastError(error.error);
      });
  };

  if (user) {
    return <Navigate to={from} replace />;
  }

  console.log("form", form.getFieldsError());

  return (
    <>
      <Layout style={{ height: "100vh" }} className="Auth">
        <div className="login-form-card-container">
          <a href="/">
            <img
              src={Logo}
              style={{
                width: 150,
                marginTop: 0,
                marginBottom: 20,
              }}
            />
          </a>
          <LText inactiveColor>Welcome Back! 🎉</LText>
          <div style={{ marginBottom: 20 }}>
            <LText inactiveColor mt8>
              Let's get you signed into your account
            </LText>
            <div style={{ marginTop: 16 }}>
              <Form
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={handleSubmit}
                autoComplete="off"
                validateTrigger={"onSubmit"}
                form={form}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter an e-mail",
                      type: "email",
                    },
                  ]}
                >
                  <Input placeholder={"Email Address"} />
                </Form.Item>

                <Form.Item
                  style={{ marginBottom: 0 }}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a password",
                    },
                    {
                      min: 8,
                      message:
                        "Password has to be a minimum of 8 characters long",
                    },
                  ]}
                >
                  <Input.Password placeholder={"Password"} />
                </Form.Item>
                <LBox>
                  <p
                    style={{
                      fontSize: "0.8rem",
                      marginTop: 10,
                      marginBottom: 20,
                      marginLeft: 4,
                      color: "var(--default-font-color)",
                    }}
                  >
                    <Link to={"/forgot-password"}>Forgot Password?</Link>
                  </p>
                </LBox>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    {"Sign In"}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>

          <p
            style={{
              fontSize: 15,
              padding: 0,
              color: "var(--default-font-color)",
            }}
          >
            <Link to={"/signup"}>New? Signup Here</Link>
          </p>
        </div>
        <div className="auth-quote">
          <p>
            When we strive to become better than we are, everything around us
            becomes better too
            <p
              style={{
                fontSize: 10,
                marginTop: 10,
                color: "var(--inactive-color)",
              }}
            >
              - Paulo Coelho
            </p>
          </p>
        </div>
      </Layout>
    </>
  );
};

export default Login;
