import React, { useMemo } from "react";
import { isEmpty, shuffle } from "lodash";
import { useSelector } from "react-redux";
import QuoteCard from "quotes/QuoteCard";
import { selectCustomQuotesForActiveCategory } from "quotes/selectors";
import LEmpty from "components/LEmpty/LEmpty";

const Custom = ({ selectedCategory }) => {
  const customQuotes = useSelector((state) =>
    selectCustomQuotesForActiveCategory(state)
  );

  return (
    <>
      {customQuotes.map((quote) => {
        return (
          <QuoteCard isCustomQuote quote={quote} key={`quote-${quote.id}`} />
        );
      })}
      {isEmpty(customQuotes) && (
        <LEmpty text="No quotes found. Start by adding a new quote!" />
      )}
    </>
  );
};

export default Custom;
