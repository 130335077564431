import React from "react";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";
import { MODAL_NAMES, toggleModal } from "slices/modalSlice";
import { updateActiveVisionBoardId } from "vision/visionSlice";
import { selectActiveVisionBoardId } from "vision/selectors";
import SidebarItem from "vision/SidebarItem";
import LText from "components/LText/LText";
import LBox from "components/LBox/LBox";
import { hasReachedFeatureLimit } from "selectors/billingSelectors";
import { FEATURE_NAMES } from "constants";
import {
  selectActiveCustomQuotesCategoryId,
  selectCustomQuotesCategoriesArray,
} from "quotes/selectors";
import CustomQuoteSidebarItem from "quotes/CustomQuoteSidebarItem";
import { updateActiveCustomQuotesCategoryId } from "quotes/quotesSlice";

const CustomQuotesSidebar = () => {
  const quoteCategories = useSelector((state) =>
    selectCustomQuotesCategoriesArray(state)
  );
  // const hasReachedVisionBoardLimit = useSelector((state) =>
  //   hasReachedFeatureLimit(state, {
  //     featureName: FEATURE_NAMES.unlimited_vision_boards,
  //   })
  // );

  const dispatch = useDispatch();
  const activeCustomQuotesCategoryId = useSelector(
    selectActiveCustomQuotesCategoryId
  );

  const setSelectedCategory = (category) => {
    dispatch(updateActiveCustomQuotesCategoryId(category.id));
  };

  return (
    <div className="vision-boards-sidebar">
      <LBox flexRowBetween alignCenter>
        <LText fs12 inactiveColor>
          My Categories
        </LText>
        <div className="add-button-container">
          <Tooltip title="New Category">
            <span>
              <PlusCircleIcon
                height="30"
                className="add-button"
                onClick={() => {
                  // if (hasReachedVisionBoardLimit) {
                  //   return dispatch(
                  //     toggleModal({
                  //       modalName: MODAL_NAMES.PREMIUM_FEATURE,
                  //     })
                  //   );
                  // }
                  //
                  dispatch(
                    toggleModal({
                      modalName:
                        MODAL_NAMES.CREATE_OR_UPDATE_CUSTOM_QUOTES_CATEGORY,
                      modalProps: { isCreate: true },
                    })
                  );
                }}
              />
            </span>
          </Tooltip>
        </div>
      </LBox>
      {quoteCategories.map((quoteCategory) => (
        <CustomQuoteSidebarItem
          activeCustomQuotesCategoryId={activeCustomQuotesCategoryId}
          quoteCategory={quoteCategory}
          setSelectedCategory={setSelectedCategory}
        />
      ))}
    </div>
  );
};

export default CustomQuotesSidebar;
