import { Button, Checkbox, Form, Input, Layout } from "antd";
import React, { useEffect, useState } from "react";
import "auth/Auth.css";
import {
  checkLoggedIn,
  login,
  register,
  requestCode,
  resetPassword,
} from "slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import useToast from "hooks/useToast";
import Logo from "images/logo-full.jpg";
import jstz from "jstz";
import LText from "components/LText/LText";

const Signup = ({ type }) => {
  const time_zone = jstz.determine();
  const [verifyCode, setVerifyCode] = useState(false);

  const { toastError, toastInfo } = useToast();
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const from = state?.from || "/";
  const navigate = useNavigate();

  useEffect(() => {
    if (user) return;

    dispatch(checkLoggedIn());
  }, []);

  const handleSubmit = (values) => {
    if (!verifyCode) {
      toastInfo("Please check your e-mail for a verification code");
      setVerifyCode(true);
      dispatch(requestCode(values.email));
    } else {
      dispatch(
        resetPassword({
          email: values.email,
          code: values.code,
        })
      )
        .unwrap()
        .catch((error) => {
          console.log("error", error);
          toastError(error.error);
        })
        .then(() => {
          toastInfo(
            "Password reset successfully. Please check your e-mail for your new password."
          );
          navigate("/");
        });
    }
  };

  if (user) {
    return <Navigate to={from} replace />;
  }

  return (
    <>
      <Layout style={{ height: "100vh" }} className="Auth">
        <div className="login-form-card-container">
          <a href="/">
            <img
              src={Logo}
              style={{
                width: 150,
                marginTop: 0,
                marginBottom: 20,
              }}
            />
          </a>
          <LText inactiveColor style={{ marginBottom: 20 }}>
            {"Enter your e-mail address to reset your password"}
          </LText>
          <div style={{ marginBottom: 20 }}>
            <div>
              <Form
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={handleSubmit}
                autoComplete="off"
                validateTrigger={"onSubmit"}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter an e-mail",
                      type: "email",
                    },
                  ]}
                >
                  <Input placeholder={"Email Address"} />
                </Form.Item>

                {verifyCode && (
                  <Form.Item
                    name="code"
                    rules={[
                      {
                        required: verifyCode,
                        message: "Please enter in a code",
                      },
                    ]}
                  >
                    <Input placeholder={"Verification Code"} />
                  </Form.Item>
                )}

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    {"Reset Password"}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </Layout>
      {/*<Footer />*/}
    </>
  );
};
export default Signup;
