import { Button, Dropdown, Space } from "antd";
import { createEntry } from "journal/journalSlice";
import React from "react";
import LBox from "components/LBox/LBox";
import {
  ChatBubbleBottomCenterIcon,
  PhotoIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { MODAL_NAMES, toggleModal } from "slices/modalSlice";
import { FEATURE_NAMES, VISION_ITEM_TYPES } from "constants";
import { hasReachedFeatureLimit } from "selectors/billingSelectors";

const AddItem = () => {
  const hasReachedImageLimit = useSelector((state) =>
    hasReachedFeatureLimit(state, {
      featureName: FEATURE_NAMES.unlimited_image_vision_items,
    })
  );

  const dispatch = useDispatch();
  const items = [
    {
      key: "1",
      onClick: () => {
        dispatch(
          toggleModal({
            modalName: MODAL_NAMES.CREATE_OR_UPDATE_VISION_ITEM,
            modalProps: { type: VISION_ITEM_TYPES.YOUTUBE, isCreate: true },
          })
        );
      },
      label: (
        <LBox flexRow>
          <LBox mr2>
            <VideoCameraIcon height="20" />
          </LBox>
          YouTube
        </LBox>
      ),
    },
    {
      key: "2",
      label: (
        <LBox flexRow>
          <LBox mr2>
            <PhotoIcon height="20" />
          </LBox>
          Image
        </LBox>
      ),
      onClick: () => {
        if (hasReachedImageLimit) {
          return dispatch(
            toggleModal({
              modalName: MODAL_NAMES.PREMIUM_FEATURE,
            })
          );
        }

        dispatch(
          toggleModal({
            modalName: MODAL_NAMES.CREATE_OR_UPDATE_VISION_ITEM,
            modalProps: { type: VISION_ITEM_TYPES.IMAGE, isCreate: true },
          })
        );
      },
    },
    {
      key: "3",
      label: (
        <LBox flexRow>
          <LBox mr2>
            <ChatBubbleBottomCenterIcon height="20" />
          </LBox>
          Note
        </LBox>
      ),
      onClick: () => {
        dispatch(
          toggleModal({
            modalName: MODAL_NAMES.CREATE_OR_UPDATE_VISION_ITEM,
            modalProps: { type: VISION_ITEM_TYPES.NOTE, isCreate: true },
          })
        );
      },
    },
  ];

  return (
    <Dropdown
      menu={{
        items,
      }}
    >
      <Button type={"primary"}>Add Vision Item</Button>
    </Dropdown>
  );
};

export default AddItem;
